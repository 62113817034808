import { RecursiveActionMap, createActions } from 'redux-actions'

import {
  assignedElementSelectors,
  assignedLearnpathAllSelectors,
  assignedLearnpathSelectors,
} from '../selectors'
import buildApiUrl from '../utils/buildApiUrl'

const actions = createActions({
  LEARNPATHS: {
    ASSIGNED: {
      GET: ({ learnpathId = undefined } = {}) => ({
        options: { returnRejectedPromiseOnError: false },
        request: {
          url: buildApiUrl.assigned(learnpathId),
        },
        learnpathId,
      }),
      ELEMENT: {
        GET: ({ learnpathId, elementId }) => ({
          request: {
            url: buildApiUrl.assignedElement(learnpathId, elementId),
          },
        }),
      },
      CONFIRM_COMPLETION: {
        POST: ({ learnpathId }) => ({
          request: {
            url: buildApiUrl.assignedConfirmCompletion(learnpathId),
            method: 'POST',
            learnpathId,
          },
        }),
      },
    },
  },
}) as RecursiveActionMap

actions.learnpaths.assigned.getIfRequired =
  ({ learnpathId = undefined } = {}) =>
  (dispatch, getState) => {
    if (learnpathId) {
      if (
        assignedLearnpathSelectors.requiresFetch(getState(), { learnpathId })
      ) {
        return dispatch(actions.learnpaths.assigned.get({ learnpathId }))
      }
    } else if (assignedLearnpathAllSelectors.requiresFetch(getState())) {
      return dispatch(actions.learnpaths.assigned.get())
    }

    return Promise.resolve()
  }

actions.learnpaths.assigned.element.getIfRequired =
  ({ learnpathId, elementId }) =>
  (dispatch, getState) => {
    const propArgs = { learnpathId, elementId }
    if (assignedElementSelectors.requiresFetch(getState(), propArgs)) {
      return dispatch(actions.learnpaths.assigned.element.get(propArgs))
    }

    return Promise.resolve()
  }

export default actions.learnpaths
