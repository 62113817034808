import { MINUTE } from '@/core/constants/constants'
import { AppLocale } from '@/locale/types/Locale'

import type { Department } from './companyDepartments'
import type { BaseUser } from './types'
import { createApiMethod, type } from './utils/createApiMethod'

export type UserRole =
  | 'admin'
  | 'company_administrator'
  | 'manager'
  | 'owner'
  | 'public_api_client'
  | 'student'

export type UserStatus = 'active' | 'pending' | 'deactivated'

export type User = {
  avatar_url: string | null
  licenses: {
    available: number
    total: number
  }
  can_be_managed_by_caller: boolean
  can_be_deleted_by_caller: boolean
  company_id: string | null
  department: Department | null
  global_user_id: string
  id: string
  invited_at: string
  is_no_email_user: boolean
  no_email_user_password_reset_at: string | null
  license_provider?: BaseUser
  roles: UserRole[]
  status: UserStatus
  user_identity: UserIdentity
}

export type UserIdentity = {
  date_of_birth: string | null
  email: string | null
  first_name: string | null
  job_title: string | null
  last_name: string | null
  no_email_user_id: string | null
  phone: string | null
  title: string | null
}

export interface UserCurrent {
  avatar_id: string | null
  avatar_url: string | null
  completed_fundamental: boolean
  current_skill_set: boolean
  email_pending_update: string | null
  extra_data?: Record<string, unknown>
  features: string[]
  field_of_activity: string | null
  field_of_activity_custom: string | null
  global_user_id: string
  id: string
  identity: UserIdentity
  interests: string[]
  lang: AppLocale
  licenses: {
    available: number
    total: number
  }
  roles: UserRole[]
  scorm: boolean
}

export type UsersListQuery = {
  page?: number
  size?: number
  email?: string
  first_name?: string
  last_name?: string
  license_provider_email?: string
  sort_by?: UsersListSort
  order?: UsersListOrder
  roles?: UserRole[]
  from_invited_at?: string
  to_invited_at?: string
  states?: UserStatus[]
}

export type UsersListSort =
  | 'status'
  | 'invitation_date'
  | 'department'
  | 'email'
  | 'first_name'
  | 'last_name'
  | 'license_provider_email'
  | 'role'
  | 'available_licenses'
  | 'total_licenses'

export type UsersListOrder = 'asc' | 'desc'

export type UsersListResponse = {
  users: User[]
  total_users: number
}

/**
 * Endpoint: /users
 */
export const users = {
  list: createApiMethod({
    method: 'GET',
    path: '/users',
    query: type<UsersListQuery>(),
    response: type<UsersListResponse>(),
    config: { paramsSerializer: { indexes: null } },
  }),

  details: createApiMethod({
    method: 'GET',
    path: '/users/:userId',
    response: type<User>(),
  }),

  create: createApiMethod({
    method: 'POST',
    path: '/users',
    data: type<{
      department_id?: string | null
      email: string
      first_name?: string | null
      last_name?: string | null
      licenses?: number
      roles: UserRole[]
      title?: 'mr' | 'mrs' | 'mx' | null
    }>(),
  }),

  update: createApiMethod({
    method: 'PUT',
    path: '/users/:userId',
    data: type<{
      email?: string
      first_name?: string
      last_name?: string
      title?: 'mr' | 'mrs' | 'mx'
    }>(),
  }),

  current: {
    details: createApiMethod({
      method: 'GET',
      path: '/users/current',
      response: type<UserCurrent>(),
    }),
  },

  search: {
    list: createApiMethod({
      method: 'GET',
      path: '/users/search',
      query: type<{ query?: string }>(),
      response: type<{ users: BaseUser[] }>(),
    }),
  },

  resetPassword: {
    update: createApiMethod({
      method: 'PUT',
      path: '/users/:userId/reset-password',
      response: type<{ new_password: string }>(),
    }),
  },

  roles: {
    update: createApiMethod({
      method: 'PUT',
      path: '/users/:userId/roles',
      data: type<{ roles: UserRole[] }>(),
    }),
  },

  departments: {
    update: createApiMethod({
      method: 'PUT',
      path: '/users/:userId/departments',
      data: type<{ department_id: string }>(),
    }),
  },

  mail: {
    create: createApiMethod({
      method: 'POST',
      path: '/users/:userId/mail',
    }),
  },

  transferLicenses: {
    create: createApiMethod({
      method: 'POST',
      path: '/users/transfer-licenses',
      data: type<{
        giver_user_id: string
        licenses: number
        receiver_user_id: string
      }>(),
    }),
  },

  deactivate: {
    create: createApiMethod({
      method: 'POST',
      path: '/users/:userId/deactivate',
    }),
  },

  reactivate: {
    create: createApiMethod({
      method: 'POST',
      path: '/users/:userId/reactivate',
    }),
  },
  delete: createApiMethod({
    method: 'DELETE',
    path: '/users/:userId',
  }),
}

export const usersQueries = {
  all: () => ['users'] as const,
  usersLists: () => [...usersQueries.all(), 'users-list'] as const,
  usersList: (params: UsersListQuery) => ({
    queryKey: [...usersQueries.usersLists(), params] as const,
    queryFn: () => users.list({ query: params }),
    staleTime: MINUTE * 5,
  }),
  usersDetails: () => [...usersQueries.all(), 'users-detail'] as const,
  usersDetail: (userId: string) => ({
    queryKey: [...usersQueries.usersDetails(), userId] as const,
    queryFn: () => users.details({ params: { userId } }),
  }),
  usersCurrentDetails: () => ({
    queryKey: [...usersQueries.all(), 'users-current-detail'] as const,
    queryFn: () => users.current.details(),
    staleTime: MINUTE * 5,
  }),
  usersSearchLists: () => [...usersQueries.all(), 'users-search-list'] as const,
  usersSearchList: (query: string) => ({
    queryKey: [...usersQueries.usersSearchLists(), query] as const,
    queryFn: () => users.search.list({ query: { query } }),
  }),
}
